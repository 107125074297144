
.auth-page {
  hr {
    width: 5rem;
    height: 1px;
    background-color: $light;
  }

  video {
    position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;width: 100%; height: 100%; z-index: 0;
  }

  .auth-page__form {
    z-index: 1;
    height: 37rem;
    width: 57rem;
    background-image: url("../img/othalan-login-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .auth-page__logo{
    position: absolute;
    top: 38%;
    left: -40%;
    width: 100%;
    height: 300px;
    object-fit: contain;
    opacity: 0.2;
    z-index: -1;
  }

  .auth-page__sponsor {
    position: absolute;
    bottom: -190px;
    right: 130px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 200px;
    min-width: 100px;
  }


  @media (max-width: 576px) { 
    .auth-page__sponsor  {
      max-width: 150px;
      right: 70px;
      bottom: -190px;
    }
  }

  @media (max-width: 450px) { 
    .auth-page__sponsor  {
      max-width: 120px;
      right: 70px;
      bottom: -202px;
    }

    .auth-page__logo{
      height: 200px;
      top: 56%;
      left: -37%;
    }
  }

  @media (max-width: 390px) { 
    .auth-page__sponsor  {
      max-width: 120px;
      right: 70px;
      bottom: -220px;
    }

  }

  .bg-settings {
    background-position: center center;
    background-repeat: no-repeat;
  }

  @media (max-width: 400px) {
    .auth-page__form {
      height: 40rem;
    }
  }
  @media (max-width: 332px) {
    .auth-page__form {
      height: 47rem;
    }
  }
  @media (max-width: 305px) {
    .auth-page__form {
      height: 61rem;
    }
  }
}


